import React, { useEffect, useState, useRef } from 'react';
import {
    GridComponent,
    ColumnsDirective,
    ColumnDirective,
    Resize,
    Sort,
    ContextMenu,
    Filter,
    Page,
    Toolbar,
    Inject,
} from '@syncfusion/ej2-react-grids';
import SETTINGS from '../setting.json';
import { toast } from 'react-toastify';

import { useNavigate } from 'react-router-dom';

import { notiList } from '../data/dummy';
import { Header } from '../components';
import { useStateContext } from '../contexts/ContextProvider';

const axios = require('axios').default;

const Notification = () => {
    const { reload, setReload } = useStateContext();
    const debounce = useRef();
    const [edit, setEdit] = useState(false);

    const [notificationAll, setNotificationAll] = useState();
    const [notification, setNotification] = useState([]);
    const [username, setUsername] = useState('');
    const [usernameSelected, setUsernameSelected] = useState(true);
    const [listUser, setListUser] = useState([]);

    const [editNoti, setEditNoti] = useState('');

    useEffect(() => {
        axios
            .get(`${SETTINGS.BASE_URL}/api/portal/notification`, {
                headers: {
                    'x-access-token': localStorage.getItem('auth_portal'),
                    'Access-Control-Allow-Origin': '*',
                },
            })
            .then(function (response) {
                let { status, notificationAll, notificationUser } =
                    response.data.result;
                if (status) {
                    setNotificationAll(notificationAll[0].notification);
                    setNotification(notificationUser);
                }
            })
            .catch(function (error) {
                toast.error('Có lỗi xảy ra', { theme: 'light' });
            });
    }, [reload]);

    const handleChangeUser = (e) => {
        setUsername(e.target.value);
        if (e.target.value.length === 0) return setListUser([]);
        clearTimeout(debounce.current);
        debounce.current = setTimeout(() => {
            axios
                .get(`${SETTINGS.BASE_URL}/api/portal/user/search`, {
                    params: { username },
                    headers: {
                        'x-access-token': localStorage.getItem('auth_portal'),
                        'Access-Control-Allow-Origin': '*',
                    },
                })
                .then(function (response) {
                    let { status, user } = response.data.result;
                    if (status === true && user.length > 0) {
                        setUsernameSelected(false);
                        setListUser(user);
                    }
                })
                .catch(function (error) {
                    toast.error('Có lỗi xảy ra', { theme: 'light' });
                });
        }, 1500);
    };

    const handleSelectUser = (username) => {
        setUsername(username);
        setUsernameSelected(true);
    };

    const handleModalEdit = () => {
        setEdit(true);
        setEditNoti('');
        setUsername('');
        setUsernameSelected(true);
    };

    const handleSubmitNoti = async () => {
        if (!username || !editNoti) {
            return toast.warn('Vui long nhập đầy đủ thông tin', {
                theme: 'light',
            });
        }
        const headers = {
            'x-access-token': localStorage.getItem('auth_portal'),
            'Access-Control-Allow-Origin': '*',
        };
        axios
            .post(
                `${SETTINGS.BASE_URL}/api/portal/edit/notification`,
                {
                    username,
                    noti: editNoti,
                    typeEdit: 'add',
                },
                {
                    headers,
                }
            )
            .then(async function (response) {
                let { status, msg } = response.data.result;
                if (status === true) {
                    setReload((pre) => !pre);
                    setEdit(false);
                    return toast.success(msg, {
                        theme: 'light',
                    });
                }
                if (status === false) {
                    toast.warn(msg, { theme: 'light' });
                }
            })
            .catch(function (error) {
                toast.error('Có lỗi xảy ra', { theme: 'light' });
            });
    };

    const handleSubmitNotiAll = () => {
        const headers = {
            'x-access-token': localStorage.getItem('auth_portal'),
            'Access-Control-Allow-Origin': '*',
        };
        axios
            .post(
                `${SETTINGS.BASE_URL}/api/portal/edit/notification`,
                {
                    noti: notificationAll,
                    typeEdit: 'notiall',
                },
                {
                    headers,
                }
            )
            .then(async function (response) {
                let { status, msg } = response.data.result;
                if (status === true) {
                    setReload((pre) => !pre);
                    setEdit(false);
                    return toast.success(msg, {
                        theme: 'light',
                    });
                }
                if (status === false) {
                    toast.warn(msg, { theme: 'light' });
                }
            })
            .catch(function (error) {
                toast.error('Có lỗi xảy ra', { theme: 'light' });
            });
    };

    return (
        <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-md">
            <Header category="Thông báo toàn bộ" title="" />
            <div className="flex mb-4">
                <input
                    defaultValue={notificationAll}
                    onChange={(e) => setNotificationAll(e.target.value)}
                    type="text"
                    className="w-full px-2 py-1 border mr-4 outline-none"
                />
                <button
                    onClick={handleSubmitNotiAll}
                    className="bg-blue-500 text-white px-3 py-2 rounded-md w-28"
                >
                    Cập nhật
                </button>
            </div>
            <Header category="Thông báo cho từng người" title="" />
            <button
                onClick={handleModalEdit}
                className="text-white bg-green-500 px-4 py-2 rounded-md mb-4 mt-[-20px]"
            >
                Thêm thông báo
            </button>
            <GridComponent
                id="gridcomp"
                dataSource={notification}
                allowPaging
                allowSorting
                allowKeyboard={false}
                toolbar={['Search']}
            >
                <ColumnsDirective>
                    {notiList.map((item, index) => (
                        <ColumnDirective key={index} {...item} />
                    ))}
                </ColumnsDirective>
                <Inject
                    services={[
                        Resize,
                        Sort,
                        ContextMenu,
                        Filter,
                        Toolbar,
                        Page,
                    ]}
                />
            </GridComponent>
            {edit && (
                <div>
                    <div className="w-[100%] h-[100%] fixed top-0 left-0 z-50 bg-[rgba(0,0,0,.7)]"></div>
                    <div className="fixed w-[calc(100%-30px)] lg:w-[calc(50%-30px)] top-[2%] left-[-50%] right-[-50%] mx-auto bg-[#fff] rounded-lg z-[100]">
                        <div className="relative van-dialog__content">
                            <div className="px-[31px] py-[25px]">
                                <div>
                                    <div className="form-group mb-[20px] relative">
                                        <p className="text-left text-[#999]">
                                            Tên thành viên
                                        </p>
                                        <input
                                            className="p-[10px] border-solid border-2 w-full"
                                            placeholder="Tên thành viên"
                                            onChange={handleChangeUser}
                                            value={username}
                                        />
                                        {!usernameSelected && (
                                            <div className="w-full bg-white absolute top-20 max-h-[200px] border overflow-auto">
                                                {listUser.map((item, index) => (
                                                    <div
                                                        key={index}
                                                        className="p-2 user-select-none cursor-pointer hover:bg-blue-500 hover:text-white"
                                                        onClick={() => {
                                                            handleSelectUser(
                                                                item
                                                            );
                                                        }}
                                                    >
                                                        {item}
                                                    </div>
                                                ))}
                                            </div>
                                        )}
                                    </div>
                                    <div className="form-group mb-[20px]">
                                        <p className="text-left text-[#999]">
                                            Thông báo
                                        </p>
                                        <input
                                            defaultValue={editNoti}
                                            className="p-[10px] border-solid border-2 w-full"
                                            placeholder="Thông báo"
                                            onChange={(e) =>
                                                setEditNoti(e.target.value)
                                            }
                                        />
                                    </div>
                                </div>
                                <div className="flex justify-between">
                                    <div
                                        onClick={() => setEdit(false)}
                                        className="w-[calc(100%)] text-center bg-[#95a5a6] mx-[15px] py-[10px] rounded-full"
                                    >
                                        <p className="text-white">Hủy</p>
                                    </div>
                                    <div
                                        onClick={handleSubmitNoti}
                                        className="w-[calc(100%)] text-center bg-[#3498db] mx-[15px] py-[10px] rounded-full"
                                    >
                                        <p className="text-white">Thêm mới</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};
export default Notification;
