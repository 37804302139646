import React, { useEffect, useState } from 'react';
import SETTINGS from '../setting.json';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import { useStateContext } from '../contexts/ContextProvider';
const axios = require('axios').default;

function calculRoses(amount, roses) {
    return (Number(amount) * (roses / 100)).toFixed(2);
}

const formatMoney = (money) =>
    new Intl.NumberFormat('en-us', {
        style: 'currency',
        currency: 'USD',
    }).format(money);

const initList = { id: 0, sttDonVip: '', minPrice: '', maxPrice: '' };

const Member = (props) => {
    let {
        username,
        money,
        level,
        status,
        roses_user,
        level_nhandon,
        dongbangtk,
        stt_don_vip,
        name_u_bank,
        name_bank,
        phone,
        stk_bank,
        address,
        totalWithdraw,
        userInvite,
        name_level,
    } = props.data;

    const { setReload } = useStateContext();

    const [edit, setEdit] = useState(false);
    const [deletes, setDelete] = useState(false);
    const [open, setOpen] = useState(false);
    const [info, setInfo] = useState(false);
    const [list_level, setListLevel] = useState([]);

    let [money_new, setMoney] = useState('0');
    let [dongbang, setDongbang] = useState(dongbangtk);
    let [vip_new, setVip] = useState(roses_user);
    // let [vip_NhanDonNew, setVipNhanDon] = useState(level_nhandon);
    let [level_new, setLevel] = useState(level);
    let [delete_bank, setDBank] = useState('0');
    let [status_new, setStatus] = useState(status);
    let [password_new, setPassword] = useState('');
    let [passbank, setPassBank] = useState('');
    let [nameBank, setNameBank] = useState(name_bank);
    let [stk, setStk] = useState(stk_bank);
    let [nameUBank, setNameUBank] = useState(name_u_bank);
    // const [sttDonVip, setSttDonVip] = useState(stt_don_vip);
    const [modalVip, setModalVip] = useState(false);
    const [listVip, setListVip] = useState([initList]);
    const [listBank, setListBank] = useState([]);
    const [reRender, setRerender] = useState(true);
    let link = '/listorderx/' + username;

    // useEffect(() => {

    //     return () => {
    //         setListLevel({}); // This worked for me
    //     };
    // }, []);

    const handleChange = (event) => {
        setVip(event.target.value);
    };

    const EditMission = async () => {
        const headers = {
            'x-access-token': localStorage.getItem('auth_portal'),
            'Access-Control-Allow-Origin': '*',
        };
        axios
            .post(
                `${SETTINGS.BASE_URL}/api/portal/edit/user`,
                {
                    username,
                    money_new,
                    password_new,
                    vip_new,
                    level_new,
                    delete_bank,
                    nameBank,
                    stk,
                    nameUBank,
                    // vip_NhanDonNew,
                    passbank,
                    dongbang,
                    // sttDonVip,
                    type: 'edit',
                },
                {
                    headers,
                }
            )
            .then(async function (response) {
                let data = response.data;
                if (data.status === 'ok') {
                    if (data.result.type === 1) {
                        setReload((pre) => !pre);
                        setEdit(false);
                        return toast.success('Cập nhật tài khoản thành công !', {
                            theme: 'light',
                        });
                    }
                }
            })
            .catch(function (error) {
                toast.error('Có lỗi xảy ra', { theme: 'light' });
            });
    };

    const handleVip = () => {
        axios
            .get(`${SETTINGS.BASE_URL}/api/portal/missionvip/list`, {
                headers: {
                    'x-access-token': localStorage.getItem('auth_portal'),
                    'Access-Control-Allow-Origin': '*',
                },
                params: {
                    username,
                },
            })
            .then(async function (response) {
                let { status, list } = response.data.result;
                if (status) {
                    setListVip(list);
                    setModalVip(true);
                } else {
                    setModalVip(true);
                }
            })
            .catch(function (error) {
                toast.error('Có lỗi xảy ra', { theme: 'light' });
            });
    };

    const handleSubmitListVip = () => {
        const check = listVip.filter(
            (item, index, self) => index === self.findIndex((t) => t.sttDonVip === item.sttDonVip)
        );
        if (check.length !== listVip.length) return toast.warn('Số nhận đơn bị trùng', { theme: 'light' });
        axios
            .post(
                `${SETTINGS.BASE_URL}/api/portal/missionvip/edit`,
                {
                    listVip,
                    username,
                },
                {
                    headers: {
                        'x-access-token': localStorage.getItem('auth_portal'),
                        'Access-Control-Allow-Origin': '*',
                    },
                }
            )
            .then((res) => {
                let { status, msg } = res.data.result;
                if (status) {
                    setModalVip(false);
                    toast.success(msg, { theme: 'light' });
                    setReload((pre) => !pre);
                }
            })
            .catch((err) => {
                console.log(err);
                toast.error('Có lỗi xảy ra', { theme: 'light' });
            });
    };

    const handleAddListVip = () => {
        let id = listVip.length;
        setListVip((pre) => [...pre, { ...initList, id }]);
    };

    const handleDeleteListVip = (id) => {
        setListVip((pre) => pre.filter((item) => item.id !== id));
    };

    const handleEditSttDonVip = (e, id) => {
        listVip[id] = {
            ...listVip[id],
            sttDonVip: Number(e.target.value),
        };
        setRerender((pre) => !pre);
    };
    const handleEditMinPrice = (e, id) => {
        listVip[id] = {
            ...listVip[id],
            minPrice: Number(e.target.value),
        };
        setRerender((pre) => !pre);
    };
    const handleEditMaxPrice = (e, id) => {
        listVip[id] = {
            ...listVip[id],
            maxPrice: Number(e.target.value),
        };
        setRerender((pre) => !pre);
    };

    const DeleteMission = async (type) => {
        const headers = {
            'x-access-token': localStorage.getItem('auth_portal'),
            'Access-Control-Allow-Origin': '*',
        };
        axios
            .post(
                `${SETTINGS.BASE_URL}/api/portal/edit/user`,
                { username, type: type },
                {
                    headers,
                }
            )
            .then(async function (response) {
                let data = response.data;
                if (data.status === 'ok') {
                    if (data.result.type === 2) {
                        setDelete(false);
                        setReload((pre) => !pre);
                        return toast.success('Khóa tài khoản thành công !', {
                            theme: 'light',
                        });
                    } else {
                        setOpen(false);
                        setReload((pre) => !pre);
                        return toast.success('Mở tài khoản thành công !', {
                            theme: 'light',
                        });
                    }
                }
            })
            .catch(function (error) {
                console.log(error);
                toast.error('Có lỗi xảy ra', { theme: 'light' });
            });
    };

    const handleEditModal = async () => {
        const [
            {
                data: { result: listLevel },
            },
            {
                data: {
                    result: { list: listBank },
                },
            },
        ] = await Promise.all([
            axios.get(`${SETTINGS.BASE_URL}/api/portal/level/list`, {
                headers: {
                    'x-access-token': localStorage.getItem('auth_portal'),
                    'Access-Control-Allow-Origin': '*',
                },
            }),
            axios.get(`${SETTINGS.BASE_URL}/api/portal/list/bank`, {
                headers: {
                    'x-access-token': localStorage.getItem('auth_portal'),
                    'Access-Control-Allow-Origin': '*',
                },
            }),
        ]);
        setListLevel(listLevel);
        setListBank(listBank);
        setEdit(true);
    };

    return (
        <>
            <div className="flex flex-col">
                <div className="flex">
                    <button
                        onClick={() => setInfo(true)}
                        className="bg-amber-600 w-full m-1 py-1 rounded-sm text-white text-[10px]"
                    >
                        Thông tin
                    </button>
                    <button
                        onClick={handleEditModal}
                        className="bg-[#3498db] w-full m-1 py-1 rounded-sm text-white"
                    >
                        Sửa
                    </button>
                </div>
                <div className="flex">
                    <button
                        onClick={handleVip}
                        className="bg-[#dbca34] w-full m-1 py-1 rounded-sm"
                    >
                        <p className="text-white">Vip</p>
                    </button>
                    {status_new === 1 && (
                        <button
                            onClick={() => setDelete(true)}
                            className="bg-[#e74c3c] w-full m-1 py-1 rounded-sm"
                        >
                            <p className="text-white">Khóa</p>
                        </button>
                    )}
                    {status_new === 2 && (
                        <button
                            onClick={() => setOpen(true)}
                            className="bg-[#2ecc71] w-full m-1 py-1 rounded-sm"
                        >
                            <p className="text-white">Mở</p>
                        </button>
                    )}
                </div>
            </div>
            {edit && (
                <div>
                    <div className="w-[100%] h-[100%] fixed top-0 left-0 z-50 bg-[rgba(0,0,0,.7)]"></div>
                    <div className="fixed w-[calc(100%-30px)] lg:w-[calc(50%-30px)] top-[2%] left-[-50%] right-[-50%] mx-auto bg-[#fff] rounded-lg z-[100]">
                        <div className="relative van-dialog__content">
                            <div className="px-[31px] py-[25px]">
                                <div>
                                    <div className="form-group mb-[20px]">
                                        <p className="text-left text-[#999]">Tài khoản</p>
                                        <input
                                            className="p-[10px] m-1 border-solid border-2 w-full"
                                            placeholder="Tài khoản"
                                            disabled="disabled"
                                            defaultValue={username}
                                        />
                                    </div>
                                    <div className="form-group mb-[20px]">
                                        <p className="text-left text-[#999]">Tăng số dư hoặc giảm số dư</p>
                                        <input
                                            onChange={(e) => setMoney(e.target.value)}
                                            className="p-[10px] border-solid border-2 w-full"
                                            placeholder="Số dư"
                                            defaultValue={money_new}
                                        />
                                    </div>
                                    <div className="form-group mb-[20px]">
                                        <p className="text-left text-[#999]">Số dư đóng băng mới</p>
                                        <input
                                            onChange={(e) => setDongbang(e.target.value)}
                                            className="p-[10px] border-solid border-2 w-full"
                                            placeholder="Số dư"
                                            defaultValue={0}
                                        />
                                    </div>
                                    <div className="form-group mb-[20px]">
                                        <p className="text-left text-[#999]">Đổi mật khẩu</p>
                                        <input
                                            onChange={(e) => setPassword(e.target.value)}
                                            className="p-[10px] border-solid border-2 w-full"
                                            placeholder="Mật khẩu mới (Lưu ý: nếu không đổi thì không nhập gì vào đây)"
                                            defaultValue={password_new}
                                        />
                                    </div>
                                    <div className="form-group mb-[20px]">
                                        <p className="text-left text-[#999]">Đổi mật khẩu rút tiền</p>
                                        <input
                                            onChange={(e) => setPassBank(e.target.value)}
                                            className="p-[10px] border-solid border-2 w-full"
                                            placeholder="Mật khẩu rút tiền mới (Lưu ý: nếu không đổi thì không nhập gì vào đây)"
                                            defaultValue={passbank}
                                        />
                                    </div>
                                    <div className="form-group my-[25px]">
                                        <p className="text-left text-[#999]">Đổi tên ngân hàng</p>
                                        <select
                                            onChange={(e) => setNameBank(e.target.value)}
                                            defaultValue={nameBank}
                                            className="form-select appearance-none
                            block
                            w-full
                            px-3
                            py-1.5
                            text-base
                            font-normal
                            text-gray-700
                            bg-white bg-clip-padding bg-no-repeat
                            border border-solid border-gray-300
                            rounded
                            transition
                            ease-in-out
                            m-0
                            focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                                        >
                                            {listBank.map((data, index) => {
                                                return (
                                                    <option
                                                        key={index}
                                                        value={data.name}
                                                    >
                                                        {data.name}
                                                    </option>
                                                );
                                            })}
                                        </select>
                                    </div>
                                    <div className="form-group mb-[20px]">
                                        <p className="text-left text-[#999]">Đổi số tài khoản ngân hàng</p>
                                        <input
                                            onChange={(e) => setStk(e.target.value)}
                                            className="p-[10px] border-solid border-2 w-full"
                                            placeholder="Đổi số tài khoản ngân hàng"
                                            defaultValue={stk_bank}
                                        />
                                    </div>
                                    <div className="form-group mb-[20px]">
                                        <p className="text-left text-[#999]">Đổi tên tài khoản ngân hàng</p>
                                        <input
                                            onChange={(e) => setNameUBank(e.target.value)}
                                            className="p-[10px] border-solid border-2 w-full"
                                            placeholder="Đổi tên ngân hàng"
                                            defaultValue={name_u_bank}
                                        />
                                    </div>
                                    <div className="form-group my-[25px]">
                                        <p className="text-left text-[#999]">Cấp Đại Lý</p>
                                        <select
                                            onChange={(e) => handleChange(e)}
                                            defaultValue={vip_new || roses_user}
                                            className="form-select appearance-none
                            block
                            w-full
                            px-3
                            py-1.5
                            text-base
                            font-normal
                            text-gray-700
                            bg-white bg-clip-padding bg-no-repeat
                            border border-solid border-gray-300
                            rounded
                            transition
                            ease-in-out
                            m-0
                            focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                                        >
                                            {list_level?.map((data, index) => {
                                                return (
                                                    <option
                                                        key={data.id_level}
                                                        value={data.id_level}
                                                    >
                                                        {data.name_level}
                                                    </option>
                                                );
                                            })}
                                        </select>
                                    </div>
                                    {/* <div className="form-group mb-[20px]">
                                        <p className="text-left text-[#999]">
                                            Mã nhận đơn
                                        </p>
                                        <input
                                            onChange={(e) =>
                                                setVipNhanDon(e.target.value)
                                            }
                                            className="p-[10px] border-solid border-2 w-full"
                                            defaultValue={vip_NhanDonNew}
                                        />
                                    </div>
                                    <div className="form-group mb-[20px]">
                                        <p className="text-left text-[#999]">
                                            Nhận đơn hàng vip tại đơn thứ:
                                        </p>
                                        <input
                                            onChange={(e) =>
                                                setSttDonVip(e.target.value)
                                            }
                                            className="p-[10px] border-solid border-2 w-full"
                                            defaultValue={stt_don_vip}
                                        />
                                    </div> */}
                                    <div className="form-group my-[25px]">
                                        <select
                                            onChange={(e) => setLevel(e.target.value)}
                                            defaultValue={level_new}
                                            className="form-select appearance-none
                            block
                            w-full
                            px-3
                            py-1.5
                            text-base
                            font-normal
                            text-gray-700
                            bg-white bg-clip-padding bg-no-repeat
                            border border-solid border-gray-300
                            rounded
                            transition
                            ease-in-out
                            m-0
                            focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                                        >
                                            <option value="0">USER</option>
                                            <option value="1">ADMIN</option>
                                        </select>
                                    </div>
                                    <div className="form-group my-[25px]">
                                        <p className="mb-2 text-[10px] text-left text-gray-400">Gỡ bỏ ngân hàng</p>
                                        <select
                                            onChange={(e) => setDBank(e.target.value)}
                                            defaultValue={delete_bank}
                                            className="form-select appearance-none
                            block
                            w-full
                            px-3
                            py-1.5
                            text-base
                            font-normal
                            text-gray-700
                            bg-white bg-clip-padding bg-no-repeat
                            border border-solid border-gray-300
                            rounded
                            transition
                            ease-in-out
                            m-0
                            focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                                        >
                                            <option value="0">Không</option>
                                            <option value="1">Có</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="flex justify-between">
                                    <div
                                        onClick={() => setEdit(false)}
                                        className="w-[calc(100%)] text-center bg-[#95a5a6] mx-[15px] py-[10px] rounded-full"
                                    >
                                        <p className="text-white">Hủy</p>
                                    </div>
                                    <div
                                        onClick={() => EditMission()}
                                        className="w-[calc(100%)] text-center bg-[#3498db] mx-[15px] py-[10px] rounded-full"
                                    >
                                        <p className="text-white">Sửa đổi</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {deletes && (
                <div>
                    <div className="w-[100%] h-[100%] fixed top-0 left-0 z-50 bg-[rgba(0,0,0,.7)]"></div>
                    <div className="fixed w-[calc(100%-30px)] lg:w-[40%] top-[30%] left-[-50%] right-[-50%] mx-auto bg-[#fff] rounded-lg z-[100]">
                        <div className="relative van-dialog__content">
                            <div className="px-[31px] py-[25px]">
                                <div className="font-microsoft text-center font-bold my-[50px]">
                                    Xác nhận khóa tài khoản
                                </div>
                                <div className="flex justify-between">
                                    <div
                                        onClick={() => setDelete(false)}
                                        className="w-[calc(100%)] text-center bg-[#95a5a6] mx-[15px] py-[5px] rounded-full"
                                    >
                                        <p className="text-white">Hủy</p>
                                    </div>
                                    <div
                                        onClick={() => DeleteMission('banned')}
                                        className="w-[calc(100%)] text-center bg-[#3498db] mx-[15px] py-[5px] rounded-full"
                                    >
                                        <p className="text-white">Đồng ý</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {open && (
                <div>
                    <div className="w-[100%] h-[100%] fixed top-0 left-0 z-50 bg-[rgba(0,0,0,.7)]"></div>
                    <div className="fixed w-[calc(100%-30px)] lg:w-[40%] top-[30%] left-[-50%] right-[-50%] mx-auto bg-[#fff] rounded-lg z-[100]">
                        <div className="relative van-dialog__content">
                            <div className="px-[31px] py-[25px]">
                                <div className="font-microsoft text-center font-bold my-[50px]">
                                    Xác nhận mở tài khoản
                                </div>
                                <div className="flex justify-between">
                                    <div
                                        onClick={() => setOpen(false)}
                                        className="w-[calc(100%)] text-center bg-[#95a5a6] mx-[15px] py-[5px] rounded-full"
                                    >
                                        <p className="text-white">Hủy</p>
                                    </div>
                                    <div
                                        onClick={() => DeleteMission('open')}
                                        className="w-[calc(100%)] text-center bg-[#3498db] mx-[15px] py-[5px] rounded-full"
                                    >
                                        <p className="text-white">Đồng ý</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {modalVip && (
                <div>
                    <div className="w-[100%] h-[100%] fixed top-0 left-0 z-50 bg-[rgba(0,0,0,.7)]"></div>
                    <div className="fixed w-[calc(100%-30px)] lg:w-[50%] top-[30%] left-[-50%] right-[-50%] mx-auto bg-[#fff] rounded-lg z-[100]">
                        <div className="relative van-dialog__content">
                            <div className="px-[31px] py-[25px]">
                                <h3 className="font-microsoft text-center font-bold text-xl mb-4">
                                    Cài đặt nhận đơn cho tài khoản: {username}
                                </h3>
                                {listVip.map((item, index) => (
                                    <div
                                        key={index}
                                        className="flex justify-center mb-4"
                                    >
                                        <div className="flex flex-col w-full mr-2">
                                            <label htmlFor="">Nhận đơn tại:</label>
                                            <input
                                                value={item.sttDonVip}
                                                onChange={(e) => handleEditSttDonVip(e, item.id)}
                                                type="text"
                                                className="border p-1"
                                            />
                                        </div>
                                        <div className="flex flex-col w-full mr-2">
                                            <label htmlFor="">Giá trị đơn lớn hơn:</label>
                                            <input
                                                value={item.minPrice}
                                                onChange={(e) => handleEditMinPrice(e, item.id)}
                                                type="text"
                                                className="border p-1"
                                            />
                                        </div>
                                        <div className="flex flex-col w-full mr-2">
                                            <label htmlFor="">Giá trị đơn nhỏ hơn:</label>
                                            <input
                                                value={item.maxPrice}
                                                onChange={(e) => handleEditMaxPrice(e, item.id)}
                                                type="text"
                                                className="border p-1"
                                            />
                                        </div>
                                        <button
                                            onClick={() => handleDeleteListVip(item.id)}
                                            className="bg-red-500 px-1 text-white"
                                        >
                                            Xoá
                                        </button>
                                    </div>
                                ))}

                                <div className="flex mb-4">
                                    <button
                                        onClick={handleAddListVip}
                                        className="p-2 rounded-sm bg-green-500 text-white"
                                    >
                                        Thêm
                                    </button>
                                </div>

                                <div className="flex justify-between">
                                    <div
                                        onClick={() => setModalVip(false)}
                                        className="w-[calc(100%)] text-center bg-[#95a5a6] mx-[15px] py-[5px] rounded-full"
                                    >
                                        <p className="text-white">Hủy</p>
                                    </div>
                                    <div
                                        onClick={handleSubmitListVip}
                                        className="w-[calc(100%)] text-center bg-[#3498db] mx-[15px] py-[5px] rounded-full"
                                    >
                                        <p className="text-white">Đồng ý</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {info && (
                <div>
                    <div className="w-[100%] h-[100%] fixed top-0 left-0 z-50 bg-[rgba(0,0,0,.7)]"></div>
                    <div className="fixed w-[calc(100%-30px)] lg:w-[40%] top-[20%] left-[-50%] right-[-50%] mx-auto bg-[#fff] rounded-lg z-[100]">
                        <div className="relative van-dialog__content">
                            <div className="px-[31px] py-[25px]">
                                <div className="font-microsoft text-center text-2xl font-bold my-[30px]">
                                    Thông tin tài khoản
                                </div>
                                <div className="flex flex-col mb-10">
                                    <span className="text-xl">
                                        Tài khoản: <span className="font-semibold">{username}</span>
                                    </span>
                                    <span className="text-xl">
                                        Số điện thoại: <span className="font-semibold">{phone}</span>
                                    </span>
                                    <span className="text-xl">
                                        Tên tài khoản: <span className="font-semibold">{name_u_bank}</span>
                                    </span>
                                    <span className="text-xl">
                                        Tên ngân hàng: <span className="font-semibold">{name_bank}</span>
                                    </span>
                                    <span className="text-xl">
                                        Số tài khoản: <span className="font-semibold">{stk_bank}</span>
                                    </span>
                                    <span className="text-xl">
                                        Địa chỉ: <span className="font-semibold">{address}</span>
                                    </span>
                                    <span className="text-xl">
                                        Số tiền: <span className="font-semibold">{formatMoney(money)}</span>
                                    </span>
                                    <span className="text-xl">
                                        Số tiền bị đóng băng:{' '}
                                        <span className="font-semibold">{formatMoney(dongbangtk)}</span>
                                    </span>
                                    <span className="text-xl">
                                        Tổng rút: <span className="font-semibold">{totalWithdraw}</span>
                                    </span>
                                    <span className="text-xl">
                                        Người mời: <span className="font-semibold">{userInvite}</span>
                                    </span>
                                    <span className="text-xl">
                                        Cấp bậc: <span className="font-semibold">{name_level}</span>
                                    </span>
                                    <span className="text-xl">
                                        Tình trạng tài khoản:{' '}
                                        <span
                                            className="font-semibold"
                                            style={{
                                                color: status === 1 ? 'green' : 'red',
                                            }}
                                        >
                                            {status === 1 ? 'Đang hoạt động' : 'Đã bị khoá'}
                                        </span>
                                    </span>
                                </div>
                                <div className="flex justify-between">
                                    <div
                                        onClick={() => setInfo(false)}
                                        className="w-[calc(100%)] text-center bg-[#3498db] mx-[15px] py-[5px] rounded-full"
                                    >
                                        <p className="text-white">Đóng</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default Member;
