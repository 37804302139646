import React, { useEffect, useState } from 'react';
import { GridComponent, ColumnsDirective, ColumnDirective } from '@syncfusion/ej2-react-grids';
import ReactPaginate from 'react-paginate';
import SETTINGS from '../setting.json';
import { toast } from 'react-toastify';

import { membersGrid } from '../data/dummy';
import { Header } from '../components';
import { useStateContext } from '../contexts/ContextProvider';
import './Pagination.scss';

const axios = require('axios').default;

const Member = () => {
    const { reload } = useStateContext();
    let [listMission, setListMission] = useState([]);
    const [page, setpage] = useState(0);
    const [search, setSearch] = useState('');
    const [totalUser, setTotalUser] = useState(0);
    useEffect(() => {
        fetchMission(page, search);
        return () => {
            setListMission({}); // This worked for me
        };
    }, [reload, page]);

    function fetchMission(page, search = '') {
        axios
            .get(`${SETTINGS.BASE_URL}/api/portal/list/users/all`, {
                params: { page, username: search },
                headers: {
                    'x-access-token': localStorage.getItem('auth_portal'),
                    'Access-Control-Allow-Origin': '*',
                },
            })
            .then(function (response) {
                let { data, totalUser } = response.data.result;
                setListMission(data);
                setTotalUser(totalUser);
            })
            .catch(function (error) {
                toast.error('Có lỗi xảy ra', { theme: 'light' });
            });
    }
    const handlePageClick = (event) => {
        setpage(() => event.selected);
    };
    const handleSubmitSearch = (e) => {
        e.preventDefault();
        fetchMission(0, search);
    };
    return (
        <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-md">
            <Header
                category="Danh sách thành viên"
                title=""
            />
            <form
                onSubmit={handleSubmitSearch}
                className="mb-4"
            >
                <input
                    type="text"
                    onChange={(e) => setSearch(e.target.value)}
                    className="border rounded-sm focus:outline-none p-2 w-[500px]"
                    placeholder="Tài khoản hoặc Id_user hoặc Mã mời"
                    autoComplete="new-password"
                />
                <button className="bg-blue-500 ml-4 text-white rounded-md px-4 py-2">Tìm</button>
            </form>
            <GridComponent
                id="gridcomp"
                dataSource={listMission}
                allowTextWrap={true}
                allowKeyboard={false}
            >
                <ColumnsDirective>
                    {membersGrid.map((item, index) => (
                        <ColumnDirective
                            key={index}
                            {...item}
                        />
                    ))}
                </ColumnsDirective>
            </GridComponent>
            <ReactPaginate
                breakLabel="..."
                className="pagination"
                nextLabel=">"
                onPageChange={handlePageClick}
                pageRangeDisplayed={3}
                pageCount={Math.ceil(totalUser / 10)}
                previousLabel="<"
                renderOnZeroPageCount={null}
                forcePage={page}
            />
        </div>
    );
};
export default Member;
